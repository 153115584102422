<template>
	<div>
		<b-modal
			id="modal-add-transfer"
			cancel-variant="outline-secondary"
			:ok-title="$t('Create')"
			:cancel-title="$t('Close')"
			centered
			size="lg"
			:title="$t('Add transfer')"
			@hidden="resetModal"
			@ok="confirmAddTransfer"
		>
			<b-row>
				<b-col
					lg="6"
					cols="12"
				>
					<table class="table table-bordered mt-2">
						<tr
							v-for="balance in balanceType"
							:key="balance.id"
						>
							<td width="50%">{{ balance.name }}</td>
							<td>{{ resolveBalanceValue(balance.id) }}</td>
						</tr>
					</table>
				</b-col>

				<b-col
					lg="6"
					cols="12"
				>
					<!-- form -->
					<validation-observer ref="addTransferForm">
						<b-form
							method="POST"
							class="auth-reset-password-form mt-2"
							@submit.prevent="validationFormAddTransfer"
						>

							<!-- Field: Loginname -->
							<b-form-group
								label="Loginname"
								label-for="loginname"
								hidden
							>
								<b-form-input
									id="loginname"
									v-model="transferData.loginname"
									disabled
								/>
							</b-form-group>

							<!-- Field: Transfer From -->
							<b-form-group
								label-for="transferFrom"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Source') }}</label>
								<validation-provider
									#default="{ errors }"
									name="TransferFrom"
									rules="required"
								>
									<v-select
										v-model="transferData.balance_type_from"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="balanceType.filter(e => e.id !== 3)"
										:reduce="val => val.id"
										label="name"
										:selectable="item => item.id !== transferData.balance_type_from"
										:class="errors.length > 0 ? 'is-invalid':null"
										@input="transferData.balance_type_to = ''"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Field: Transfer To -->
							<b-form-group
								label-for="transferTo"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Destination') }}</label>
								<validation-provider
									#default="{ errors }"
									name="TransferTo"
									rules="required"
								>
									<v-select
										v-model="transferData.balance_type_to"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="balanceType.filter(e => e.id !== 3)"
										:reduce="val => val.id"
										label="name"
										:class="errors.length > 0 ? 'is-invalid':null"
										:selectable="item => item.id !== transferData.balance_type_from"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Field: Amount -->
							<b-form-group
								label-for="amount"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Amount') }}</label>
								<validation-provider
									#default="{ errors }"
									name="Amount"
									rules="required|numeric|min_value:10"
								>
									<b-form-input
										v-model="transferData.amount"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										input-id="amount"
										:class="errors.length > 0 ? 'is-invalid':null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Bonus Code -->
							<!-- <b-form-group
								label-for="bonusCode"
								label="Bonus Code"
							>
								<b-form-input
									v-model="transferData.bonusCode"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									input-id="bonusCode"
								/>
							</b-form-group> -->

						</b-form>
					</validation-observer>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { numberFormat } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		balanceType: {
			type: Array,
			required: true,
			default: null,
		},
    balancesList: {
			type: Array,
			required: true,
			default: null,
		},
		userData: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			stat: 1,
			note: '',
			transferData: {
				loginname: this.userData.username,
				balance_type_from: '',
				balance_type_to: '',
				amount: '',
				bonusCode: null
			},
			balances: '',
		}
	},
	watch: {
	},
	async mounted() {
		// await this.getUserBalances()
	},
	methods: {
		resolveBalanceValue(balanceId) {
			if (this.balancesList) {
				const balance = this.balancesList.find(item => item.balance_type.id === balanceId)
				return (balance !== undefined) ? numberFormat(parseFloat(balance.value) / 1000) : 0
			}
			return 'n/a'
		},
		// async getUserBalances() {
		// 	await store.dispatch('app-user/fetchUserBalance', router.currentRoute.params.id)
		// 		.then(response => {
		// 			this.balances = response.data.data
		// 		})
		// },

		validationFormAddTransfer() {
			this.$refs.addTransferForm.validate().then(success => {
				if (success) {
					store.dispatch('payment-transfer/addTransfer', this.transferData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											// this.getUserBalances()
											this.$bvModal.hide('modal-add-transfer')
										}, '500')
										setTimeout(() => {
											this.resetModal()
										}, '1000')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
				}
			})
		},
		resetModal() {
			this.transferData.balance_type_to = ''
			this.transferData.balance_type_from = ''
			this.transferData.amount = ''
			this.transferData.bonusCode = null
		},
		confirmAddTransfer(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormAddTransfer()
		}
	},
	setup() {
		const statusOptions = [
			{ label: 'Locked', value: 0 },
			{ label: 'Active', value: 1 },
			{ label: 'Verify', value: 2 },
		]

		return {
			statusOptions,
		}
	},
}
</script>

<style>

</style>
